<template>
    <div>
        <div v-if='showRestrictionModal'>
            <BasePlanRestriction :showModal='showRestrictionModal' @close='showRestrictionModal = false'/>
        </div>
        <div class="settings-users settings-right-area" id="settings-users" v-if="user && !showRestrictionModal">
            <div class="process-category" v-if="page_type === 2 || page_type === 3">
                <span class="m-right-2">Users & Permissions</span>
                <i class="el-icon-arrow-right"></i>
                <span class="m-left-2" v-if="page_type === 2">New User</span>
                <span class="m-left-2" v-if="page_type === 3">Edit User</span>
            </div>

            <right-header-footer :class="{'m-top-3': page_type === 1}" class="header-items">
                <template v-slot:title>
                    <span v-if="page_type === 1">Users {{ user.currentTeam.users.length }}</span>
                    <span v-if="page_type === 2">New User</span>
                    <span v-if="page_type === 3">Edit User</span>
                </template>



                <template v-slot:actions>
                    <div :class='page_type===2 ? "d-flex" : ""'>
                        <MobileMenu 
                            :isMobile='true'
                            v-if='page_type == 1'
                        ></MobileMenu>
                        <button
                            @click="setPageType(2)"
                            class="btn btn-primary"
                            type="button"
                            v-if="page_type === 1 && user.currentTeam.role === 'owner' && !freePack"
                        >Create User
                        </button>
                        <button
                            class="btn btn-primary"
                            type="button"
                            v-if="freePack"
                        >Create User
                        </button>
                        <el-button
                            @click="setPageType(1)"
                            round class="mb-mob-2 cancel-btn"
                            type="info"
                            v-if="page_type === 2 || page_type === 3"
                        >Cancel
                        </el-button>
                        <el-button
                            @click="saveUser"
                            round class="mb-mob-2 save-btn"
                            type="primary"
                            v-if="page_type === 2 || page_type === 3"
                        >Save User
                        </el-button>
                    </div>
                </template>
            </right-header-footer>

            <div class="project-permission">
                <el-switch
                        :active-value="1"
                        :inactive-value="0"
                        active-color="#0AD688"
                        active-icon-class="el-icon-check success"
                        slot="reference"
                        v-model="user.settings.hide_project_from_users"
                        @change="updateUserPermission"
                ></el-switch> Hide projects from non-collaborating users
            </div>

            <user-list
                :logged_in_user = user
                :role="user.currentTeam.role"
                :sub_users="user.currentTeam.users"
                :freePack='freePack'
                v-if="page_type === 1"
                v-on:delete-dialog="deleteDialogShow"
                v-on:set-sel-user="selUser"
            ></user-list>

            <create-edit-user :page-type="page_type" :user="user" v-if="page_type === 2"></create-edit-user>

            <create-edit-user
                :page-type="page_type"
                :sel-user="sel_user"
                :user="user"
                v-if="page_type === 3"
            ></create-edit-user>

            <el-dialog
                :top="mobileCheck ? `10vh` : `30vh`"
                :visible.sync="deleteDialog"
                :width="mobileCheck ? `100%` : `600px`"
                center
                custom-class="pages-custom-dialog"
                title="ARE YOU SURE?"
            >
                <el-container>
                    <div class="align-items-center justify-content-center">
                        <img alt src="../../assets/img/settings/delete-user-popup-img.svg"/>
                    </div>
                    <div class="m-top-3 text-center font-weight-bold delete-header-title">Heads Up!</div>
                    <div class="m-top-2 text-center">
                        The owner of your account has not granted you the permission to perform this action.
                    </div>
                </el-container>
                <span class="dialog-footer" slot="footer">
                    <el-button @click="deleteDialog = false" round type="primary">Got it!</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import RightHeaderFooter from "./Layout/RightHeaderFooter";
    import UserList from "./Items/UserList";
    import CreateEditUser from "./Items/CreateEditUser";
    import MobileMenu from '../Settings/Layout/MobileMenu';

    export default {
        name: "TeamUsers",
        components: {CreateEditUser, UserList, RightHeaderFooter, MobileMenu},
        props: {
            user: Object
        },
        data: () => ({
            page_type: 1,
            sel_user: {},
            deleteDialog: false,
            freePack: 'NA',
            showRestrictionModal: true,
        }),
        computed: {
            mobileCheck: {
                get() {
                    let BC_onMobile = false;
                    if (
                        window.screen.availWidth <= 414 ||
                        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                        )
                    ) {
                        BC_onMobile = true;
                    }

                    return BC_onMobile;
                }
            }
        },
        beforeDestroy() {
            window.vEvent.stop("set-user-page-type", this.setPageType);
        },
        mounted() {
            window.vEvent.listen("set-user-page-type", this.setPageType);
            this.getPlan();
        },
        created(){
          console.log(this.user, 'user');
        },
        methods: {
            updateUserPermission(){

                this.axios({
                    method: "post",
                    url: "subuser/permission",
                    data: {
                        hide_project_from_users: this.user.settings.hide_project_from_users
                    }
                })
                    .then(response => {

                    })
                    ["catch"](error => {

                });

console.log('updateUserPermission', this.user.settings.hide_project_from_users);
            },
            setPageType(type) {
                this.page_type = type;
                document.querySelector("body").scrollTop = 0;
                document.querySelector("#app").scrollTop = 0;
            },
            selUser(user) {
                this.sel_user = user;
            },
            saveUser() {
                window.vEvent.fire("save-sub-user");
            },
            deleteDialogShow() {
                this.deleteDialog = true;
            },
            getPlan()
            {
                this.axios.post('owner-plan').then(r => {
                    console.log(r.data.name);
                    if (r && r.data) {
                        if (r.data.name == 'Free Forever' || r.data.name == 'Starter') {
                            this.freePack = true;
                        } else {
                            this.freePack = false;
                        }
                    }
                });
            },
        }
    };
</script>

<style lang="less" scoped>
    .settings-users {
        position: relative;

        .process-category {
            font-size: 85%;
            margin-bottom: 1.5rem;
        }
        .mb-mob-2{
            @media (max-width: 414px) {
                margin-bottom: 8px
            }
        }

    }

    .project-permission{
        margin-bottom: 15px;
    }

    .cancel-btn {
        background-color: #909399 !important;
        border-color: #909399;
        color: #fff !important;
    }

    @media (max-width: 960px) {
        .save-btn {
            padding: 10px !important;
            font-size: 12px;
        }
        .cancel-btn {
            padding: 10px !important;
            font-size: 12px;
        }
    }
</style>
